import React from "react";
import Layout from "../../../components/Layout";
import Episode from "../../../components/Podcast/Episode";
import podcastEpisodes, { pageKeywords } from "../../../utils/podcastEpisodes";

export default ({ location }) => {
  const lang = "ja";

  // Page setting
  const episodeNo = 52;
  const pageImage = "";
  const episode = podcastEpisodes.find(ep => ep.id === episodeNo);
  const episodeContent = (
    <>
      <ul className="basicList">
        <li>柴犬とのミックス犬いろいろ</li>
        <li>チワワ(Chihuahua)はメキシコの州の名前</li>
        <li>ニューヨークにはペットショップはない？</li>
        <li>ペット店での子犬販売、禁止へ</li>
        <a
          href="https://www.dailysunny.com/2021/05/11/nynews210511-4/"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.dailysunny.com/2021/05/11/nynews210511-4/
        </a>
        <a
          href="https://www.turnto23.com/new-york-to-ban-animal-sales-in-pet-stores/"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.turnto23.com/new-york-to-ban-animal-sales-in-pet-stores/
        </a>
        <li>ペットを衝動買いできるペットショップの問題</li>
        <li>柴犬と洋犬の違い</li>
        <li>
          <a
            href="https://www.youtube.com/watch?v=yCe0LxQfBvg"
            target="_blank"
            rel="noopener noreferrer"
          >
            Digging Deep: What Makes Shiba Inu Dogs So Special?
          </a>
        </li>
      </ul>
    </>
  );

  return (
    <Layout
      location={location}
      lang={lang}
      pageTitle={episode.title}
      pageSlug={episode.slug}
      pageKeywords={pageKeywords}
      pageDescription={episode.description}
      pageImage={pageImage}
    >
      <Episode
        episodeNo={episodeNo}
        episodeTitle={episode.title}
        episodeDescription={episode.description}
        episodeContent={episodeContent}
        episodeURL={episode.embed}
      />
    </Layout>
  );
};
